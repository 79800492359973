<template>
  <div class="labs-page">
    <v-row v-if="isLoading" class="justify-center mt-2">
      <v-progress-circular color="primary" indeterminate />
    </v-row>
    <div v-else>
      <div v-if="!url">
        <v-card color="#fff" flat>
          <v-card-title class="pt-6 pb-9 px-4">
            <h3 v-if="warningHeader">{{ warningHeader }}</h3>
            <h3 v-else>
              <span>{{ warningHeader1 }}</span>
              <span style="color: #cb2a2a">{{ warningHeader2 }}</span>
              <br />
              <span>{{ warningHeader3 }}</span>
            </h3>
          </v-card-title>
        </v-card>
        <div v-if="!disabled" class="pt-6 px-4">
          <Btn
            :disabled="viewPartner"
            theme="primary"
            class="mb-4"
            @click="goToLink('male')"
          >
            Male Form
          </Btn>
          <Btn :disabled="viewPartner" theme="primary" @click="goToLink">
            Female Form
          </Btn>
        </div>
      </div>
      <div v-else style="background: #fff">
        <iframe :src="url" style="width: 100%; height: 91vh"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import { auth, api, partner } from '../../sharedPlugin';

export default {
  data: () => ({
    url: null,
    warningHeader: '',
    warningHeader1:
      'To get started, we need to know more about you such as your family medical history and information about any fertility testing and prior fertility treatments. This will help our physicians create a personalized treatment protocol for your specific history and needs. Please ensure you have the time and necessary information to complete the form. This process can take up to 45 minutes and',
    warningHeader2: ' you cannot save and return to the form.',
    warningHeader3:
      'Select a form below based on your biological sex assigned at birth.',
    disabled: false,
    isLoading: false
  }),
  computed: {
    ...auth.mapComputed(['user']),
    ...partner.mapComputed(['getPartner']),
    viewPartner() {
      return this.getPartner?.viewPartner;
    }
  },
  methods: {
    goToLink(type) {
      console.log(this.user);
      let url = `https://form.123formbuilder.com/sf.php?s=123formbuilder-6385010&control106453569=${this.user.userId}`;

      if (type === 'male') {
        url = `https://form.123formbuilder.com/sf.php?s=123formbuilder-6395851&control106453432=${this.user.userId}`;
      }

      this.url = url;
    },
    getIntakeFormStatus() {
      let url = window.location.href;
      let result = url.replace(/(^\w+:|^)\/\//, '');
      let index = result.indexOf(':');
      url = result.substring(0, index);

      this.isLoading = true;
      api.IntakeEndpoint.getStatus()
        .then((result) => {
          if (result && result.length > 0) {
            this.warningHeader =
              'Thank you for completing your health history form. Your information has been successfully submitted.';
            this.disabled = true;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    }
  },
  created() {
    this.getIntakeFormStatus();
  }
};
</script>
<style lang="scss">
.labs-page {
  .v-card__title {
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}

#form {
  background: #ffffff;
}
</style>
